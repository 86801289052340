@import "./theme_color";
#root {
  height: 100vh !important;
}

////////////////////////////////////////////////// Print/////////////////////////////////////////////////@at-root
@media print {
  @page {
    // size: A4 landscape;
    size: A4 portrait;
    margin: 7mm 7mm;
  }
}

////////////////////////////////////////////////// Print/////////////////////////////////////////////////@at-root

.bg-item-menu > div {
  background-color: $item_menu_color;
  // padding: 6.8px 15px; //origin
  padding: 8px 15px;
  border-bottom: 1px solid white;
}
.bg-item-menu > div:hover {
  background-color: $item_menu_hover_color;
}
.bg-item-menu-active > div {
  background-color: $item_menu_active_color;
}

.ant-layout-sider-children .bg-item-menu .bg-item-menu-active {
  background-color: $item_menu_active_color;
}

.bg-item-menu-active > div:hover {
  background-color: rgb(255, 217, 0);
}

.scope-content {
  // padding: 30px 10px;
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

.sidebar-main {
  width: 150px;
}

.my-shadow-box {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
}

// .sidebar-main {
//   max-width: 150px !important;
//   min-width: 150px !important;
//   height: 100%;
//   overflow: auto !important;
//   position: fixed !important;
//   left: 0;
//   z-index: 999;
//   background-color: #faa41a;
// }

//Login
.bg-login {
  height: 100vh;
  width: auto;
  background-image: url("../img/login/Back Log.svg");
  background-size: cover;
}
.page-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.card-login {
  width: 570px;
  height: 80%;
}

.span-topic1 {
  font-size: 33px;
  font-weight: bold;
  color: #231f20;
}

.span-topic2 {
  font-size: 20px;
  font-weight: normal;
  color: #231f20;
}

.ant-input-affix-wrapper-lg.login-input {
  border-radius: 32px;
  height: 60px;
  margin-top: 5px;
  box-shadow: 0 3px 15px #00000029;
}

.group-bottom-login {
  display: flex;
  justify-content: space-between;
}
.bottom-login {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  opacity: 0.67;
  letter-spacing: 0.36px;
}

.icon-login {
  font-size: 18px;
  font-weight: bold;
  margin: 0 15px;
}

.ant-btn-block.btn-login {
  background: #ff6b0b no-repeat padding-box;
  box-shadow: 0px 3px 29px #00000029;
  width: 168px;
  height: 50px;
  border-radius: 45px;
  font-size: 18px;
  font-weight: normal;
}

.checkbox-login {
  border-radius: 2px;
}

// .card-login {
//   width: 60%;
//   box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.1);
//   padding: 20px !important;
// }

// @media only screen and (max-width: 1366px) {
//   .sidebar-main {
//     max-width: 160px !important;
//     min-width: 160px !important;
//   }
// }
// @media only screen and (max-width: 1024px) {
//   .sidebar-main {
//     max-width: 120px !important;
//     min-width: 120px !important;
//     display: grid !important;
//     position: absolute !;
//   }
// }
//
.ant-cascader-input.ant-input {
  // min-height: 50px;
  min-height: 45px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  // min-height: 50px;
  min-height: 45px;
  align-items: center;
}

//SCROLLBAR MENUITEM/////////////////////////////////
.scrollbar-custom {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}

.scrollbar-custom::-webkit-scrollbar-track {
  order: 1px solid rgb(226, 226, 226);
  padding: 2px 0;
  background-color: #fafafa;
}

.scrollbar-custom::-webkit-scrollbar {
  width: 6px;
  border: 1px solid rgb(226, 226, 226);
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  // display: block;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e9e9e9;
  border: 1px solid rgb(223, 223, 223);
}
//SCROLLBAR MENUITEM/////////////////////////////////

// color
.primay_color {
  color: $primay_color;
}
//////////////////////////////////////btn tab menu customer service////////////////////////////////////////////////

button.list-custom-btn {
  // border: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  border-bottom: 0px;
  border-radius: 0px;
  width: 250px;
  height: 55px;
  font-size: 18px;
  font-family: "Bold";
  background-color: white;
  cursor: pointer;
  border-top-left-radius: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
button.list-custom-btn:hover {
  color: $item_menu_active_color;
}
button.customer-btn-custom {
  // border: 0px;
  border-right: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  border-bottom: 0px solid #d9d9d9;
  border-radius: 0px;
  width: 250px;
  height: 55px;
  font-size: 18px;
  font-family: "Bold";
  background-color: white;
  cursor: pointer;
  border-left: 2px solid $gray_body;
  border-top-right-radius: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
button.customer-btn-custom:hover {
  color: $item_menu_active_color;
}
.font-color-btn-tab-active {
  color: $item_menu_hover_color;
}

.font-color-btn-tab {
  color: black;
}

//////////////////////////////////////         Dashboard         ////////////////////////////////////////////////

.border-bottom-5rem {
  border-bottom: 1px solid gray;
  width: 5.5rem;
}

//////////////////////////////////////btn tab menu customer service////////////////////////////////////////////////

//////////////////////////////////////Card sub tab menu customer service////////////////////////////////////////////////
.card-menu-tab {
  color: black;
  padding: 15px 30px 5px 15px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
div.ant-card.ant-card-bordered.card-menu-tab {
  border: 1px solid #bbbbbb;
}
.card-menu-tab:hover {
  color: #231f20 !important;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.card-menu-tab-active {
  color: white !important;
  background-color: #ffc627 !important;
  padding: 15px 30px 5px 15px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.card-menu-tab-active:hover {
  background-color: #fcd526 !important;
}

.box-row-card {
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.card-menu-uniq {
  background-color: white;
  color: black;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.card-menu-uniq:hover {
  background-color: white;
  color: black;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.card-menu-green {
  background-color: green !important;
  color: white !important;
  border: 1px solid rgb(0, 85, 0) !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.card-menu-lightblue {
  background-color: #208ab5 !important;
  color: white !important;
  border: 1px solid #033f63 !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.card-menu-red {
  background-color: #fe613f !important;
  color: white !important;
  border: 1px solid #a51e00 !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

//////////////////////////////////////Card sub tab menu customer service////////////////////////////////////////////////

//////////////////////////////////////btn tab menu customer service////////////////////////////////////////////////

button.user-custom-btn {
  border: 0px;
  border-radius: 0px;
  width: 250px;
  height: 55px;
  font-size: 20px;
  font-family: "Bold";
  background-color: white;
  border-radius: 0px 0px 4px 4px;
}
button.user-custom-btn:hover {
  background-color: #faa41a;
  color: white;
}
button.role-btn-custom {
  border: 0px;
  border-radius: 0px;
  width: 250px;
  height: 55px;
  font-size: 20px;
  font-family: "Bold";
  background-color: white;
  cursor: pointer;
  border-left: 2px solid $gray_body;
  border-radius: 0px 0px 4px 4px;
}
button.role-btn-custom:hover {
  background-color: #faa41a;
  color: white;
}
.custom-color-btn-tab-active {
  color: white;
  background-color: #faa41a !important;
}

.custom-color-btn-tab {
  color: black;
}

.pdf-btn {
  width: 150px;
  line-height: 1.5715;
  margin-top: -1px;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  border-radius: 4px;
  background-color: white;
  // background-color: #f7f7f7;
  font-family: "Bold";
  font-size: 18px !important;
  height: 51px !important;
  cursor: pointer;
  border: 1px solid transparent;
  color: rgba(0, 0, 0, 0.65);
  // border-color: #d9d9d9;
  border-color: #bfbfbf;
  user-select: none;
  touch-action: manipulation;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.pdf-btn-text {
  padding: 0 10px;
}

.pdf-btn:hover {
  border: 2px solid $item_menu_active_color;
  color: $item_menu_active_color !important;
}

.item-from-header-btn {
  height: 50px !important;
  width: 150px;
  height: 100%;
  font-family: "Bold";
  font-size: 18px !important;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
}

//////////////////////////////////////User profile////////////////////////////////////////////////
.user-profile-photo {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}
.card-table {
  background-color: white;
  padding: 0 10px;
  // margin-top: 10px;
  border-radius: 5px;
}

//////////////////////////////////////User profile////////////////////////////////////////////////

//////////////////////////////////////////Input Field////////////////////////////////////////////
.float-label {
  position: relative;
  margin-bottom: 12px;
  transition: all 0.3s;
}

.label {
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 10px;
  transition: 0.2s ease all;
  box-sizing: content-box;
  height: 1.1876em;
  color: rgb(209, 209, 209);
}

.label-float-focused {
  font-family: "Bold";
  color: #faa41a;
  top: -8.5px;
  font-size: 13px;
  outline: 2px;
  outline-offset: 2px;
  transform: translate(0px, -2px) scale(1);
  // z-index: 9999;
  z-index: 11;
  background-color: #fff;
  padding: 0 5px;
}

.label-float {
  font-family: "Bold";
  color: lightgray;
  top: -8.5px;
  font-size: 13px;
  outline: 2px;
  outline-offset: 2px;
  transform: translate(0px, -2px) scale(1);
  // z-index: 9999;
  z-index: 11;
  background-color: #fff;
  padding: 0 5px;
}

.ant-input:focus,
.ant-input-focused .label .label-float {
  font-family: "Bold";
  color: black;
}

.background-label-float {
  background-color: white;
  top: -2px;
  height: 2px;
  padding: 3px;
  position: absolute;
  left: 8px;
  z-index: 11;
}

.input-from-custom {
  // height: 50px;
  min-height: 50px;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
}

.input-from-custom2 {
  // height: 50px;
  min-height: 50px;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  width: 320px !important;
}

.range-picker-custom {
  min-height: 50px;
}
.date-picker-custom {
  min-height: 50px;
}
.ant-picker.ant-picker-large.date-picker-custom .ant-picker-input > input {
  text-align: center;
}
.cascader-custom {
  // height: 50px;
  min-height: 50px;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
}
.select-custom {
  // height: 50px;
  min-height: 50px;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
}
.select-custom-name {
  min-height: 50px;
}

.select-custom2 {
  // height: 50px;
  min-height: 50px;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  width: 267px !important;
}

.label-custom-float {
  font-size: 15px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 17.5px;
  display: flex;
  flex: none;
  align-items: center;
  transition: 0.2s ease all;
  box-sizing: content-box;
  height: 1.1876em;
  color: #d1d1d1;
  z-index: 11;
}

//////////////////////////////////////////Input Field////////////////////////////////////////////

//////////////////////////////////////////User Table////////////////////////////////////////////
.user-data-table {
  width: 100%;
  height: 520px;
  overflow-x: hidden !important;
  overflow-y: auto;
  margin-bottom: 25px;
  padding-right: 10px;
  font-family: "Bold";
}

.user-data-table p {
  color: $gray_clolr6;
  font-size: 15px;
  margin-bottom: 1px;
}
.user-data-table h1 {
  margin-bottom: 1px;
  font-size: 18px;
}
.pagination-custom {
  display: flex;
  justify-content: flex-end;
  font-family: "Bold";
}
.line-custom {
  border: 1px solid $item_menu_active_color;
  background-color: $item_menu_active_color;
  width: 100%;
}
//////////////////////////////////////////User Table////////////////////////////////////////////
.scroll-item-card-board {
  // height: 70vh;
  overflow-y: auto !important;
  width: 100%;
  display: list-item;
}
/* width */
.scroll-item-card-board::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.scroll-item-card-board::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #eeeeee;
}

/* Handle */
.scroll-item-card-board::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 10px;
  // border: 1px solid rgb(202, 202, 202);
}

/* Handle on hover */
.scroll-item-card-board::-webkit-scrollbar-thumb:hover {
  background: $gray_dark;
}
////////////////////////////////////////// image ////////////////////////////////////////////

.black-to-white {
  -webkit-filter: invert(100%); /* Safari/Chrome */
  filter: invert(100%);
}

////////////////////////////////////////// Other ////////////////////////////////////////////

.ant-card-bordered.cardItemForm {
  border-radius: 10px !important;
}

.fontDark {
  color: #231f20 !important;
  font-size: 17px;
}
.fontgrey {
  color: gray !important;
}

///////////////////////////////////////////////////*Lead Board*/////////////////////////////////////////////
.tab-board {
  width: 100%;
  // padding: 10px 5px;
  padding: 10px 2px;
  overflow-x: auto;
  overflow-y: hidden;
}

.tab-board-mobile {
  width: 100%;
  // padding: 10px 5px;
  padding: 10px 2px;
  overflow-x: auto;
  overflow-y: hidden;
}

.tab-board::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

/* Track */
.tab-board::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.tab-board::-webkit-scrollbar-thumb {
  background: #f7f7f7;
  border-radius: 10px;
}

/* Handle on hover */
.tab-board::-webkit-scrollbar-thumb:hover {
  background: #c7c7c7;
}

.table-board {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px !important;
}

.card-item-header-board {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px !important;
  color: black;
  padding: 5px 15px;
  border-radius: 5px;
  margin: 5px;
  background-color: #efefef;
  border: 1px solid transparent;
  border-color: #d9d9d9;
}

.card-item-board {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px !important;
}
.ant-btn.btn-header-collape {
  position: relative;
}
.q-icon {
  background-color: white;
  padding: 3px;
  border-radius: 50%;
}
.content-collape {
  // margin-left: 10px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  // width: 100%;
  font-size: 17px;
}
.content-collape-origin {
  margin-left: 10px;
  white-space: nowrap;
  width: 100%;
  font-size: 17px;
  word-wrap: break-word;
}
.content-origin {
  margin-left: 10px;
  width: 100%;
  font-size: 17px;
  word-wrap: break-word;
}
.text-overflow-setting {
  // margin-left: 10px;
  white-space: nowrap;
  width: 100%;
  // font-size: 17px;
  word-wrap: break-word;
}

.ant-btn.btn-header-collape:hover,
.ant-btn.btn-header-collape:focus,
.ant-btn.btn-header-collape:active {
  color: #ffc627ff !important;
  background: #fff;
  border-color: #e9e9e9 !important;
}

/////////////Button edit password///////////////
.ant-btn.btn-editpass {
  height: 45px;
  width: 100%;
  background-color: #808284;
  border-radius: 2px;
  color: white;
}
///////////////Button edit Profile////////////////#ManageProfile Page
.ant-btn.btn-editProfile {
  margin-top: 38px;
}

///////////////Button edit////////////////#HerderSetting Page
.ant-btn.btn-edit {
  height: 45px;
  width: 110px;
  border-radius: 4px;
  border: 1px solid #7070702e;
  opacity: 1;
}

.ant-btn.btn-edit b {
  margin-left: 5px;
  font-size: 20px;
}
///////////////Button Delte////////////////#HerderSetting Page
.ant-btn.btn-Del {
  height: 45px;
  width: 110px;
  border-radius: 4px;
  border: 1px solid #7070702e;
  opacity: 1;
  background-color: red;
  color: white;
}
.ant-btn.btn-Del:hover,
.ant-btn.btn-Del:focus {
  background-color: rgb(255, 83, 83);
  color: white;
}

.ant-btn.btn-Del b {
  margin-left: 5px;
  font-size: 20px;
}
///////////////Button exporot PDF////////////////#HerderSetting Page
.ant-btn.btn-Del {
  height: 45px;
  width: 110px;
  border-radius: 4px;
  border: 1px solid #7070702e;
  opacity: 1;
  background-color: #f3422b;
  color: white;
}
.ant-btn.btn-Del:hover,
.ant-btn.btn-Del:focus {
  background-color: rgb(255, 83, 83);
  color: white;
}

.ant-btn.btn-Del b {
  margin-left: 5px;
  font-size: 20px;
}
///////////////////////////////////////////////////*Lead Board*/////////////////////////////////////////////

.form-item-children {
  width: 80%;
  margin-bottom: 0px;
}

.layout-detail-form {
  padding: 40px 50px;
  border: 1px solid lightgray;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 15px;
}

////////////////////antd select report //////////////////////////page report

.select-report .ant-select-selector {
  height: 51px !important;
  width: 150px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  font-size: 18px !important;
  // padding-left: 25px !important;
  text-align: center !important;
  padding-top: 10px !important;
}

.re-default-input.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0 4px;
  line-height: 35px !important;
  font-size: 16px;
  transition: all 0.3s;
}
//----------------CardFilter::::DirectCustomerPage-------------------////
.card-bd-1 {
  border-color: green !important;
}

.card-bd-2 {
  border-color: red !important;
}

.card-bd-3 {
  border-color: rgb(35, 177, 224) !important;
}

/////////////////////scrollbar-direct-customer/////////////////////
.my-content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.my-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: lightgray;
}

.my-content::-webkit-scrollbar-thumb:hover {
  background-color: #c7c7c7;
}

.my-content::-webkit-scrollbar-track {
  background-color: white;
}

.ant-table-body::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background-color: #e1e1e1;
}

.ant-table-body::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

.ant-table-body::-webkit-scrollbar-track {
  border-radius: 15px;
  background-color: #edf0f5;
}

.card-find-list {
  border: 1px solid #edf0f5;
  border-radius: 5px;
  height: 250px;
  overflow: auto;
}
.item-name-find-list {
  // background-color: rgb(247, 247, 247);
  margin: 5px;
  border-radius: 2px;
  cursor: pointer;
}

.item-name-find-list:hover {
  background-color: rgb(243, 243, 243);
}

.card-find-list::-webkit-scrollbar {
  width: 7px;
}

.card-find-list::-webkit-scrollbar-thumb {
  border-radius: 15px;
  // height: 50px;
  min-height: 50px;
  background-color: #c7c7c7;
}

.card-find-list::-webkit-scrollbar-track {
  border-radius: 15px;
  background-color: #edf0f5;
}

.customer-table-database td.ant-table-cell {
  cursor: pointer;
  text-align: left;
}

.header-modal-customer-database {
  background-color: #dddee3;
  padding: 15px;
  color: black;
  font-size: 16px;
  font-weight: bold;
}

.header-modal-customer-database-pdf {
  background-color: #dddee3;
  padding: 15px;
  color: black;
  font-size: 16px;
  font-weight: bold;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.card-modal-customer-database {
  border: 1px solid #edf0f5;
  border-radius: 5px;
  height: 500px;
  overflow: auto;
}

.card-modal-customer-database-PDF {
  border: 1px solid #edf0f5;
  border-radius: 5px;
}

.card-modal-customer-database::-webkit-scrollbar {
  width: 7px;
}

.card-modal-customer-database::-webkit-scrollbar-thumb {
  border-radius: 15px;
  // height: 50px;
  min-height: 50px;
  background-color: #c7c7c7;
}

.card-modal-customer-database::-webkit-scrollbar-track {
  border-radius: 15px;
  background-color: #edf0f5;
}

.text-value-card-modal {
  font-weight: bold;
  color: black;
}

.PDF-btn-modal {
  background-color: #f1f1f2;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 5px;
}

.PDF-btn-modal:hover {
  background-color: #f8f8f8;
  transition: all 0.3s;
}

.side-card-sticky {
  border-radius: 10px;
  top: 100px;
  position: sticky;
}

.shadow-standard {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.round-image {
  border-radius: 4px;
}

.formlabel.ant-form-item {
  margin-bottom: unset !important;
}
.formlabel {
  .float-label {
    margin-bottom: unset !important;
  }
  .ant-input-password input.ant-input {
    min-height: 43px !important;
  }
}

.row-same-item-table {
  padding: 10px 0;
  // border-bottom: 1px solid #f0f0f0;
}

.btn-acknowledge {
  font-size: 18px !important;
  padding-left: 2rem !important;
  height: 50px !important;
  padding-right: 2rem !important;
  background-color: rgb(250, 164, 26);
}

.text-overflow-detail {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.text-overflow-info {
  text-align: right;
  width: 74%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.img-item-note {
  border-radius: 50%;
  border: 1px solid #7070702e;
  background-color: #0000000a;
  margin-top: 9px;
  object-fit: cover;
}

.card-note-show .ant-card-body {
  padding: 15px;
}
.ant-card.ant-card-bordered.card-note-show {
  border-radius: 0px 0px 4px 4px;
}

.item-list-file-note {
  text-align: center;
  border: 1px solid #7070702e;
  background-color: #2b2b2b0a;
  padding: 6px 8px;
  border-radius: 3px;
  // width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-list-file-note:hover {
  .del-item-file-icon {
    display: unset !important;
    transition: all 0.3s;
  }
}

.del-item-file-icon {
  transition: all 0.3s;
  display: none;
  border: 1px solid #7070702e;
  background-color: white;
  border-radius: 50%;
  // padding: 2px;
  text-align: center;
  width: 15px;
  height: 15px;
  font-size: 9px;
  position: absolute;
  top: 55px;
  color: lightgray;
  cursor: pointer;
  /////////////////////
  margin: -3px;
}

.del-item-file-icon :hover {
  color: red;
}

.btn-sourte-note {
  padding: 5px 3px;
  background-color: #ebebeb;
  border-radius: 3px;
  margin: 5px 0;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
}

.btn-sourte-note:hover {
  background-color: #f1f1f1;
}

.activity-progress {
  padding: 15px;
  background-color: rgb(246, 247, 248);
  border: 1px solid #7070702e;
  border-radius: 5px;
}

.font-activitty {
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.acton-btn-text {
  height: 35px;
  width: 150px;
  background-color: #f6f7f8;
  margin: 5px 0;
  font-weight: bold;
  border-radius: 3px;
  transform: all 2s;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 0 15px;
  transition: all 5ms;
}

.acton-btn-text:hover {
  background-color: rgb(184, 184, 184);
  color: rgb(243, 243, 243);
}

.btn-done-step {
  padding: 0 15px;
  height: 35px;
  cursor: pointer;
  width: 230px;
  background-color: #f6f7f8;
  border-radius: 3px;
  margin: 5px 0;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  transition: all 5ms;
  font-weight: bold;
}

.btn-done-step:hover {
  background-color: rgb(184, 184, 184);
  color: rgb(255, 255, 255);
}

.faq-btn {
  font-size: 18px;
  transition: all 0.3s;
}

.faq-btn:hover {
  color: red;
}
.faq-btn-profile {
  font-size: 18px;
  transition: all 0.3s;
  color: #c2c2c2 !important;
}

.faq-btn-profile:hover {
  color: red !important;
}
.faq-btn-edit {
  font-size: 18px;
  transition: all 0.3s;
}

.faq-btn-edit:hover {
  color: #1e90ff;
}

.ant-btn-dangerous {
  // width: 50px;
  color: white !important;
  border-color: #f5222d;
  background: #f5222d !important;
}

.ant-btn-dangerous:hover {
  background: #faa41a !important;
}
hr {
  height: 0.8px;
  border: 0.8px solid #ededed;
}

.btn-vsb-header {
  background-color: #fff;
  transition: all 0.3s;
}

.btn-vsb-header:hover {
  background-color: #faa41a !important;
  color: white !important;
}

.customer-add-header .ant-card-body {
  padding: 15px;
  background-color: rgb(221, 222, 227);
  border-radius: 10px 10px 0px 0px !important;
  cursor: pointer;
  border-color: lightgray;
}

.ant-card.customer-add-header {
  border-radius: 10px 10px 0px 0px !important;
  border: 1px solid lightgray;
}

.customer-add-header-not .ant-card-body {
  padding: 15px;
  background-color: rgb(221, 222, 227);
  border-radius: 10px 10px 0px 0px !important;
  border-color: lightgray;
}

.ant-card.customer-add-header-not {
  border-radius: 10px 10px 0px 0px !important;
  border: 1px solid lightgray;
}

.ant-card.customer-add-body-side {
  border-radius: 5px !important;
  border: 1px solid lightgray;
}

.customer-add-body-side .ant-card-body {
  border-radius: 5px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.btn-logout-head {
  background-color: rgb(214, 0, 0);
  width: 130px;
  border: 1px solid white;
  color: white;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  font-family: "SemiBold";
}

.btn-logout-head:hover {
  background-color: rgb(235, 0, 0);
}

.title-menu-text {
  font-family: "Bold";
  color: #faa41a;
  font-size: 25px;
  // margin: 0 0px 0px 0px; // top left bottom right
}

.title-message-text {
  font-family: "Bold";
  font-size: 18px;
  margin: 0 5px;
}

.title-menu-card .ant-card-body {
  padding: 15px;
}

.ant-card.ant-card-bordered.title-menu-card {
  border-radius: 10px 10px 0px 0px;
}

//////////////////////////////=======Upload=======/////////////////////////////////////

.my-upload-custom .ant-upload-list-item {
  position: relative;
  height: 30px;
  margin-top: 8px;
  font-size: 14px;
  padding: 0px;
  width: 250px;
}

.my-upload-custom a.ant-upload-list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.my-upload-customer_sv .ant-upload-list-item {
  // position: relative;
  // height: 30px !important;
  margin: 8px;
  // font-size: 14px;
  // padding: 0px;
  // width: 250px;
}

.my-upload-customer_sv a.ant-upload-list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.text-name-file-upload {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80px;
  text-align: center;
}
.text-name-file-upload-detail {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60px;
  text-align: center;
  padding-top: 5px;
  font-size: 10px;
}
//////////////////////////////=======Upload=======/////////////////////////////////////
.fillter-heabar {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  transition: 0.4s ease-out;
}

.fillter-heabar::-webkit-scrollbar {
  height: 7px;
}

.fillter-heabar::-webkit-scrollbar-thumb {
  background: #e2e2e2;
  border-radius: 10px;
}

.fillter-heabar::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

div.close-tab-table-btn {
  background-color: lightgray;
  padding: 3px 10px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: 0.4s ease-out;
}

div.close-tab-table-btn:hover {
  background-color: #f37d31;
}

.mx-center-text {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.font-label {
  color: #b5b5b5;
  font-size: 13px;
}

.font-label-value {
  font-weight: bold;
  color: black;
  font-size: 15px;
}

.font-label-value-project {
  font-weight: bold;
  color: black;
  font-size: 16px;
}

.form-tab-bg {
  background-color: white;
  padding: 25px 20px 15px 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.card-status-tag {
  border-radius: 5px;
  color: white;
  padding: 5px 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: rgb(22, 186, 255);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 14rem;
}

.action-step-content {
  background: white;
  padding: 10px;
  width: 250px;
  height: auto;
  max-height: 250px;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.65);
  border: 1px solid rgb(228, 228, 228);
  overflow-y: auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.action-step-content::-webkit-scrollbar {
  width: 5px;
}

.action-step-content::-webkit-scrollbar-track {
  background: #f0f2f5;
}

.action-step-content::-webkit-scrollbar-thumb {
  background: rgb(209, 209, 209);
  border-radius: 10px;
}

.action-step-content::-webkit-scrollbar-thumb:hover {
  background: rgb(92, 92, 92);
}

.text-title-card-filter {
  white-space: nowrap;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  text-align: center;
}

.text-card-filter {
  white-space: nowrap;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.text-number-legend {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  text-align: right;
}

.chart-height {
  overflow: auto;
  height: 10.6rem;
}

.chart-height::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.chart-height::-webkit-scrollbar-track {
  background: #f0f2f5;
}

.chart-height::-webkit-scrollbar-thumb {
  background: rgb(209, 209, 209);
  border-radius: 10px;
}

.chart-height::-webkit-scrollbar-thumb:hover {
  background: rgb(92, 92, 92);
}

.text-chart-overflow {
  white-space: nowrap;
  width: 7.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
.number-chart-overflow {
  white-space: nowrap;
  width: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: contents;
  color: rgba(0, 0, 0, 0.85) !important;
}

.img-upload-multi {
  max-height: 64px;
  object-fit: cover;
}

//////////////////////////////////////////////////////////Animate 404 page ///////////////////////////////////////

.Iam {
  // padding: 0em 5em;
  padding: 0em 0em 0rem 17rem;
  font: normal 40px/50px Montserrat, sans-serif;
  color: #999;
}
.Iam p {
  height: 50px;
  float: left;
  margin-right: 0.3em;
}
.Iam b {
  float: left;
  overflow: hidden;
  position: relative;
  height: 50px;
  top: 40px;
}
.Iam .innerIam {
  display: inline-block;
  color: #e74c3c;
  position: relative;
  white-space: nowrap;
  top: 0;
  left: 0;

  /*animation*/
  -webkit-animation: move 5s;
  -moz-animation: move 5s;
  -ms-animation: move 5s;
  -o-animation: move 5s;
  animation: move 5s;
  /*animation-iteration-count*/
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  /*animation-delay*/
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -ms-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
}
@keyframes move {
  0% {
    top: 0px;
  }
  20% {
    top: -50px;
  }
  40% {
    top: -100px;
  }
  60% {
    top: -150px;
  }
  80% {
    top: -200px;
  }
}

@-webkit-keyframes move {
  0% {
    top: 0px;
  }
  20% {
    top: -50px;
  }
  40% {
    top: -100px;
  }
  60% {
    top: -150px;
  }
  80% {
    top: -200px;
  }
}
@-moz-keyframes move {
  0% {
    top: 0px;
  }
  20% {
    top: -50px;
  }
  40% {
    top: -100px;
  }
  60% {
    top: -150px;
  }
  80% {
    top: -200px;
  }
}
@-o-keyframes move {
  0% {
    top: 0px;
  }
  20% {
    top: -50px;
  }
  40% {
    top: -100px;
  }
  60% {
    top: -150px;
  }
  80% {
    top: -200px;
  }
}
@keyframes move {
  0% {
    top: 0px;
  }
  20% {
    top: -50px;
  }
  40% {
    top: -100px;
  }
  60% {
    top: -150px;
  }
  80% {
    top: -200px;
  }
}
///////

.content404 {
  position: relative;
}

.content404 .t404-text {
  color: #fff;
  font-size: 14em;
  position: absolute;
  // transform: translate(-50%, -50%);
  transform: translate(-30%, -50%);
}

.content404 .t404-text:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 2px #ff9900;
}

.content404 .t404-text:nth-child(2) {
  color: #ff9900;
  animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      16% 44%,
      33% 50%,
      54% 60%,
      70% 61%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 60%,
      15% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }
}

.ant-btn.customer-service-btn-action {
  width: 100%;
  height: 50px;
  font-size: 18px;
  padding-left: 2rem;
  padding-right: 2rem;
}

.ant-btn.action-cs-del,.ant-btn.action-cs-del:focus {
  background-color: red;
  color: white;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-btn.action-cs-del:hover {
  background-color: rgb(245, 72, 72);
  color: white;
}