@font-face {
    font-family: 'normal';
    /* src: url('Kanit-Regular.eot'); */
    src: url('SukhumvitSet-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SemiBold';
    /* src: url('Kanit-Medium.eot'); */
    src: url('SukhumvitSet-SemiBold.ttf') format('truetype');
    /* font-weight: bold; */
    /* font-style: normal; */
}
@font-face {
    font-family: 'Bold';
    /* src: url('Kanit-Medium.eot'); */
    src: url('SukhumvitSet-Bold.ttf') format('truetype');
    /* font-weight: bold; */
    /* font-style: normal; */
}
@font-face {
    font-family: 'Thin';
    /* src: url('Kanit-Medium.eot'); */
    src: url('SukhumvitSet-Thin.ttf') format('truetype');
    /* font-weight: normal;
    font-style: normal; */
}
@font-face {
    font-family: 'Text';
    /* src: url('Kanit-Medium.eot'); */
    src: url('SukhumvitSet-Text.ttf') format('truetype');
    /* font-weight: normal;
    font-style: normal; */
}
@font-face {
    font-family: 'Light';
    /* src: url('Kanit-Medium.eot'); */
    src: url('SukhumvitSet-Light.ttf') format('truetype');
    /* font-weight: normal;
    font-style: normal; */
}

