$primay_color: #573c81;
$violet_color: #00a1fe;
$light_color: #f7f8fa;
$white_color: #ffffff;
$black_color: #000000;
$success_color: #1fcb00;
$danger_color: #f20202;
$orange_color: #fa4238;
$gray_color1: #f1eff4;
$gray_color2: #f1f1f1;
$gray_color3: #cbcbcb;
$gray_color4: #b2b2b2;
$gray_color5: #ededed;
$gray_clolr6: #666666;
$gray_color7: #c2c2c2;

$item_menu_color: #fe6b0b;
$item_menu_hover_color: #f37d31;
$item_menu_active_color: #ffc627;
$gray_dark: #808284;
$gray_body: #f7f7f7;
$gray_btn: #4d4f5c;
