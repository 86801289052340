.image-file-cs {
  text-align: center;
  font-size: 10px !important;
}

.leads-card-title {
  background-color: #dddee3;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  h3 {
    margin: 0;
  }
}

.leads-card-title-ativity {
  background-color: #eeeff1;
  border: 1px solid #7070702e;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  h3 {
    margin: 0;
  }
}

.lead-card-title-btn {
  @extend .ml-2;
  cursor: pointer;
  border-radius: 5px;
  background-color: #fefefeba;
  border: 1px solid #ebebeb;
  padding: 6px 9px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.lead-card-title-btn:hover {
  background-color: #bbbbbbba;
  border: 1px solid #ebebeb;
  color: white;
}
.lead-card-title-btn-cancel {
  @extend .ml-2;
  cursor: pointer;
  border-radius: 5px;
  background-color: #fefefeba;
  border: 1px solid #ebebeb;
  color: red;
  padding: 6px 9px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.lead-card-title-btn-cancel:hover {
  background-color: #bbbbbbba;
  border: 1px solid #ebebeb;
  color: white;
}

.lead-card-item-detail {
  overflow-y: auto;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  max-height: 100%;
}

.lead-card-item-detail::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.lead-card-item-detail::-webkit-scrollbar-track {
  border-radius: 10px;
  margin: 3px;
}

/* Handle */
.lead-card-item-detail::-webkit-scrollbar-thumb {
  background: #dddee3;
  border-radius: 10px;
}

/* Handle on hover */
.lead-card-item-detail::-webkit-scrollbar-thumb:hover {
  background: $gray_dark;
}

.font-bold {
  font-weight: bold;
}

.card-lead-detail-modal .ant-modal-header {
  padding: 10px 10px 0 10px;
}

.ant-btn.btn-card-detail-lead {
  width: 100%;
  background-color: #ededed !important;
  font-weight: bold !important;
  border-radius: 5px ​ !important;
}

.ant-btn.btn-card-detail-lead:hover,
.ant-btn.btn-card-detail-lead:focus {
  color: white !important;
  background-color: #fdb714 !important;
}

.ant-btn.btn-card-detail-revert {
  width: 100%;
  background-color: #faa41a !important;
  color: white !important;
  font-weight: bold !important;
  border-radius: 5px ​ !important;
}

.ant-btn.btn-card-detail-revert:hover,
.ant-btn.btn-card-detail-revert:focus {
  color: white !important;
  background-color: #fdb714 !important;
}
.ant-btn.btn-card-detail-delete {
  width: 100%;
  background-color: rgb(230, 0, 0) !important;
  color: white !important;
  font-weight: bold !important;
  border-radius: 5px ​ !important;
}

.ant-btn.btn-card-detail-delete:hover,
.ant-btn.btn-card-detail-delete:focus {
  color: white !important;
  background-color: rgb(255, 8, 8) !important;
}

.btn-card-detail-lead-done {
  width: 100%;
  background-color: #faa41a !important;
  color: white !important;
  font-weight: bold !important;
  border-radius: 5px ​ !important;
  transition: all 3ms;
}

.btn-card-detail-lead-done:hover {
  background-color: #fdb714 !important;
}

img {
  vertical-align: middle;
  border-style: none;
  overflow: hidden;
}

.text-overflow-csm {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-overflow-ms {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
}

.break-word {
  overflow-wrap: break-word;
}

.my-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 35px !important;
}

.pdf-icon-overlay {
  position: relative;
  display: flex;
}

.pdf-icon-overlay:hover .pdf-file-overlay{
  opacity: 1;
}

.pdf-file-overlay {
  position: absolute;
  font-size: 10px;
  top: 0px;
  left: 0px;
  color: white;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 0.3s;
  opacity: 0;
  cursor: pointer;
}

.eye-icon-overlay {
  position: absolute;
  top: 10%;
  left: 33%;
}

.text-file-overlay {
  position: absolute;
  top: 45%;
  left: 8%;
}
