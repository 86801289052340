.setting-cover-card.ant-card-bordered {
  border-radius: 10px;
}
.setting-cover-card {
  .ant-card-cover {
    padding: 18px 24px;
  }
  .ant-card-body {
    padding: 0px 12px 12px 12px;
  }
}

.card-round.ant-card-bordered {
  border-radius: 4px !important;
  padding: 10px 0;
}

.card-padding-space {
  .ant-card-body {
    padding: 30px;
  }
}

.circle-img {
  border-radius: 50%;
  object-fit: cover;
}

.ant-upload.ant-upload-select-picture-card {
  height: 14rem !important;
  width: 14rem !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  border-radius: 50% !important;
}

.user-image {
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  height: 7vw;
}

.cut-text-1-row {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.custom-devide.ant-divider-horizontal {
  margin: 24px 0 12px 0 !important;
}

.icon-image {
  width: 45px;
  height: 45px;
  object-fit: cover;
}

.upload-list .ant-upload-list-item-name {
  color: black !important;
}
