.text-14bold {
  font-size: 14px;
  font-weight: bold;
}
.text-14 {
  font-size: 14px;
  font-weight: 500;
}

.text-overflow {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}