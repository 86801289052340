@import "./theme_color";

.ant-layout-header {
  background: #fff !important;
  border-bottom: 2px solid #faa41a;
  height: 11vh !important; // height: 80px !important;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  padding-top: 40px !important;
  color: #fcd901 !important;
  font-size: 18px;
}

.ant-spin.spiner-custom {
  color: #cfcaa7 !important;
  font-weight: bold;
  font-size: 18px;
}

.css-606pct {
  position: relative;
  width: 50px;
  // top: 189px;
  display: contents;
  height: 50px;
  -webkit-transform: rotate(165deg);
  -moz-transform: rotate(165deg);
  -ms-transform: rotate(165deg);
  transform: rotate(165deg);
}

.ant-btn {
  border-color: #d9d9d9 !important;
}

aside.ant-layout-sider.ant-layout-sider-dark {
  flex: 0 0 200px;
  // max-width: 130px !important; origin
  max-width: 140px !important;
  min-width: 115px !important;
  width: 135px !important;// 115px !important;
  background: #fe6b0b !important;
  overflow-y: auto;

  position: fixed;
  z-index: 1000;
  height: 90vh;
  margin-top: 11vh;
}

aside.ant-layout-sider.ant-layout-sider-dark::-webkit-scrollbar {
  width: 6px;
  
}

aside.ant-layout-sider.ant-layout-sider-dark::-webkit-scrollbar-track {
  background: #f0f2f5;
}

aside.ant-layout-sider.ant-layout-sider-dark::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 10px;
}

aside.ant-layout-sider.ant-layout-sider-dark::-webkit-scrollbar-thumb:hover {
  background: $gray_dark;
}
.ant-modal-wrap::-webkit-scrollbar {
  width: 10px;
}

.ant-modal-wrap::-webkit-scrollbar-track {
  background: #f0f2f5;
}

.ant-modal-wrap::-webkit-scrollbar-thumb {
  background: rgb(174, 174, 174);
  border-radius: 10px;
}

.ant-modal-wrap::-webkit-scrollbar-thumb:hover {
  background: rgb(92, 92, 92);
}

// td.ant-table-column-sort {
//   background: white !important;
// }
// .ant-table-thead th.ant-table-column-sort {
//   background: #fafafa !important;
// }

.ant-table-body {
  overflow-y: auto !important;
  overflow-x: auto !important;
}
//////////////////////////////////////  Dashbord  ////////////////////////////////////////////////

.card-dashboard {
  border-radius: 10px !important;
  color: rgb(255, 255, 255) !important;
  background: rgb(255, 255, 255) !important;
  .ant-card-actions {
    border-radius: 0 0 10px 10px;
    height: 10px !important;
  }
}
.card-bottom {
  border-radius: 10px !important;
  .ant-card-actions {
    border-radius: 0 0 10px 10px;
  }
}
.card-bt-orange > .ant-card-actions {
  background: #ff6b0b;
}
.card-bt-blue > .ant-card-actions {
  background: #16baff;
}
.card-bt-yellow > .ant-card-actions {
  background: #faa41a;
}
.card-bt-red > .ant-card-actions {
  background: #db4b28;
}

.end-form > .ant-form-item {
  margin-right: 0px !important;
}
.select-line
  > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #faa41a !important;
  border-color: #faa41a !important;
  color: #ffffff !important;
  border-radius: 5px;
  height: 2.5rem;
  padding-top: 5px;
}
.select-line > .ant-radio-button-wrapper {
  border: unset !important;
  border-right: unset !important;
}
.select-line > .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: unset;
}
//////////////////////////////////////Menu Setting////////////////////////////////////////////////
.card-menu .ant-card-body {
  padding: 0px !important;
  border-radius: 10px;
}

.card-menu-item .ant-card-body {
  padding: 15px !important;
  display: flex;
  align-items: center;
  height: 100%;
}

.ant-card .ant-card-bordered .card-menu-item {
  border-radius: 0px !important;
  border: 0px !important;
}

////////////////////////////////// Header setting /////////////////////////////////////
.border-header-diy {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 0.5px;
}
.ant-card.ant-card-bordered.border-header-diy {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.border-header-diy .ant-card-body {
  padding: 10px 30px;
}
.border-header-diy-no-tab .ant-card-body {
  padding: 10px 24px;
}
.card-round.ant-card.ant-card-bordered {
  border-radius: 10px;
}
.card-round {
  .ant-card-body {
    padding: 10px 24px;
  }
}

.ant-card.ant-card-bordered.header-setting.border-header-diy-no-tab {
  // z-index: 1060 !important;
  position: fixed;
  top: 11vh;
  z-index: 999 !important;
}

.header-setting {
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 999 !important;
}

.header-seting .ant-card-body {
  padding-bottom: 5px !important;
}

.btn-gradient:hover {
  background-image: unset !important;
  background-color: $item_menu_active_color !important;
}
.btn-gradient2:hover {
  background-image: unset !important;
  background-color: $item_menu_active_color !important;
}
.btn-gradient {
  background-image: linear-gradient(
    73deg,
    $item_menu_active_color,
    $item_menu_hover_color
  ) !important;
  font-family: "Bold";
  font-size: 17px !important;
  height: 50px !important;
  width: 150px !important;
  transition: all 0.5s;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
}

.btn-gradient2 {
  background-image: linear-gradient(
    $item_menu_active_color,
    $item_menu_hover_color
  ) !important;
  font-family: "Bold";
  font-size: 17px !important;
  height: 50px !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  transition: all 0.5s;
}

.ant-btn.btn-gradient-rycicle {
  background-image: linear-gradient(#b0b2b3, #808284) !important;
  font-family: "Bold";
  font-size: 17px !important;
  height: 50px !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  transition: all 0.5s;
}

.btn-gradient-rycicle:hover {
  background-image: unset !important;
  background-color: $item_menu_active_color !important;
}

.btn-gray {
  background-color: $gray_color5 !important;
  font-size: 17px !important;
  color: $black_color !important;
  height: 45px !important;
  width: 110px !important;
  border: none !important;
  font-family: "Bold";
}
.ant-btn.btn-gray:hover {
  background-color: rgba(0, 0, 0, 0.16) !important;
  font-size: 17px !important;
  color: white !important;
  height: 45px !important;
  width: 110px !important;
  border: none !important;
  font-family: "Bold";
}
.btn-gray-transfer {
  background-color: $gray_color5 !important;
  font-size: 17px !important;
  color: $black_color !important;
  height: 50px !important;
  width: 110px !important;
  border: none !important;
  font-family: "Bold";
}
.btn-save {
  background-color: #fd8c40 !important;
  font-size: 17px !important;
  height: 45px !important;
  width: 110px !important;
  border: none !important;
  font-family: "Bold";
}
.ant-btn.btn-save:hover {
  background-color: #ffc627 !important;
}

.btn-step {
  height: 45px !important;
  margin-left: 5px;
}
.btn-trasfer {
  // background: $item_menu_active_color !important;
  font-size: 17px !important;
  height: 50px !important;
  width: 110px !important;
  border: none !important;
  font-family: "Bold";
}
.page-heder {
  // padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
///////////////////////////////////Table andt//////////////////////////////////////////
table {
  border: 0px solid $gray_color5 !important;
}
.ant-table-header {
  border-radius: 4px 4px 0 0;
  border: 1px solid $gray_color5;
}
.ant-table-body {
  border: 1px solid $gray_color5;
  border-radius: 0 0 4px 4px;
}
.ant-table-thead > tr > th {
  border-bottom: 0px solid #f0f0f0 !important;
}
th.ant-table-cell {
  font-family: "Bold";
}

.ant-table-thead > tr > th:not(:last-child)::before {
  right: 0;
  width: 0px !important;
  height: 1.6em;
}
.action-table {
  font-size: 18px;
  color: $gray_color7;
}

//**************** select input overline ******************//
.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 45px !important;
}

.select-field-custom {
  width: 100%;
}

.re-default-input {
  width: 100%;
}
.re-default-input.ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-select-selection-overflow {
  width: 100%;
  // min-height: 50px;
  min-height: 46px;
  align-items: center;
}

.re-default-input.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0 5px;
  line-height: 35px !important;
  font-size: 16px;
  transition: all 0.3s;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  min-height: 50px;
}

.float-label
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 100%;
  // height: 50px !important;
  min-height: 50px;
  transition: all 0.3s;
}

.float-label .ant-select-single .ant-select-selector .ant-select-selection-item,
.float-label
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  padding: 0;
  line-height: 50px !important;
  font-size: 16px;
  transition: all 0.3s;
} //add .float-label in second line

.float-label input.ant-input {
  // height: 50px;
  min-height: 50px;
  transition: all 0.3s;
}

.float-label .ant-select {
  width: 100%;
  transition: all 0.3s;
}

.float-label .ant-input-affix-wrapper-lg {
  padding: 0px 11px;
  font-size: 16px;
  transition: all 0.3s;
}

.float-label textarea.ant-input {
  padding-top: 10px;
  transition: all 0.3s;
}

.cascader-custom input#fromheader_cascarder {
  min-height: 50px;
}
.select-custom > .ant-select-selector {
  // height: 50px !important;
  min-height: 50px;
  min-width: 10rem !important;
  display: flex;
  align-items: center;
  text-align: center;
}

//////////////////////////////////////avatar/////////////////////////////////////////
.card-user .ant-card-body {
  padding: 20px;
}

span.ant-avatar.ant-avatar-square.ant-avatar-icon.avatar-custom {
  width: 125px;
  height: 125px;
}

.re-default-input {
  min-height: 50px;
}

//////////////////////////////////////avatar/////////////////////////////////////////
.card-user .ant-card-body {
  padding: 20px;
}

span.ant-avatar.ant-avatar-square.ant-avatar-icon.avatar-custom {
  width: 125px;
  height: 125px;
}
button.ant-btn.ant-btn-block.btn-user-detail {
  background-color: #ededed;
  border: none;
}
button.ant-btn.ant-btn-block.btn-user-detail:hover {
  color: white;
  background-color: rgb(131, 131, 131);
}

/////////////////////////////////transfer////////////////////////////////////////////

.ant-transfer-list {
  width: 100% !important;
}

.ant-transfer-list-header {
  height: 50px !important;
}

span.ant-transfer-list-header-dropdown {
  display: none;
}

.ant-transfer-list-content {
  width: 100%;
}

.ant-transfer-list-content::-webkit-scrollbar {
  width: 5px;
}

.ant-transfer-list-content::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 4px;
}

.ant-transfer-list-content::-webkit-scrollbar-thumb:hover {
  background: $gray_dark;
}

//---------------------------------------------------

textarea.ant-input::-webkit-scrollbar {
  width: 5px;
}

textarea.ant-input::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 4px;
}

.ant-table-thead > tr > th {
  color: rgb(0, 0, 0) !important;
  font-weight: bold !important;
  font-family: "normal";
  text-align: left;
  background: #fafafa;
}

td.ant-table-cell {
  color: black;
  font-family: "normal";
  // font-weight: bold;
  font-weight: normal;
}

//////////////////////////////////////list/////////////////////////////////////////

.ant-list-bordered {
  border-radius: 5px !important;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline [class*="-upload-list-rtl"] .ant-upload-list-item {
  float: right;
}

.ant-btn-primary {
  text-shadow: unset !important;
}

.float-label
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #bfbfbf;
}

.float-label .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.float-label .ant-input {
  min-height: 50px;
  transition: all 0.3s;
  border: 1px solid #bfbfbf;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #bfbfbf;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #bfbfbf;
}

div.ant-picker {
  border: 1px solid #bfbfbf;
}

div.ant-picker:hover,
div.ant-picker-focused {
  border-color: #ffbd42;
}

span.ant-input-affix-wrapper {
  border: 1px solid #bfbfbf;
}

span.ant-input-affix-wrapper-focused {
  border: 1px solid #ffbd42;
}

.ant-cascader-input.ant-input {
  border: 1px solid #bfbfbf;
  min-height: 50px;
}


body {
  background: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

body::-webkit-scrollbar-track {
  background: #f0f2f5;
}

body::-webkit-scrollbar-thumb {
  background: rgb(209, 209, 209);
  border-radius: 8px;
}

body::-webkit-scrollbar-thumb:hover {
  background: rgb(92, 92, 92);
}